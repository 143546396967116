
/* ! Type styling */

.cvContent h6 {
  @apply pt-6 text-xxs tracking-wide uppercase;
}

.cvContent h4 {
  @apply py-6 ;
}

.cvContent p {
  @apply my-2;
}

.text-cat {
  @apply text-xxs tracking-wide uppercase ;
}

.difference {
  @apply text-white mix-blend-difference ;
}

/* a {
  @apply hover:text-white;
} */

.prose p a {

  /* ! background swipe */
  
  @apply  underline  underline-offset-[3px] hover:decoration-marcusBlue ;
  

  /* @apply hover:opacity-100 hover:decoration-marcusBlue underline underline-offset-[3px] before:mix-blend-multiply hover:before:scale-y-100 hover:before:origin-bottom relative before:w-full before:h-[1.3rem] before:origin-bottom before:transition-transform before:duration-300 before:ease-out before:scale-y-0 before:bg-marcusBlue before:absolute before:top-[-2px] hover:no-underline; */
  

  }
  


/* ! TAG styling */

.tag-line {
  stroke: #0000ff6e;
  stroke-dasharray: 2;
  fill: transparent;
  pointer-events: none;
}

.tag-text {
  @apply fill-marcusBlue;
  text-anchor: middle;
  cursor: pointer;
}


.text-rect {
  @apply fill-baseGrey;
}

/* ! OTHER */

.sixteenNine {
    width: 100%;
    padding-top: 56.25%; /* 1:1 Aspect Ratio */
    position: relative; /* If you want text inside of it */
}

hr {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  border: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
}

.bottomCaption {
    @apply text-center text-white m-auto text-xl px-5 md:text-5xl absolute bottom-0 left-0 w-full z-20 pb-14 md:pb-16 text-shadow-lg;
}

.topCaption {
    @apply text-center text-white m-auto text-4xl md:text-7xl hidden md:block absolute top-0 left-0 w-full z-20 pt-8 text-shadow-lg;
}

.cursor-left-arrow {
    /* cursor: url('../images/leftArrow.svg') 25 25, w-resize; */
    cursor: none;
}

.cursor-right-arrow {
    /* cursor: url('../images/rightArrow.svg') 25 25, e-resize; */
    cursor: none;
}

.footerHeaders {
  @apply uppercase text-sm pb-6;
 }

.indexBlockInfo {
  @apply absolute bottom-0 left-0 text-white text-center w-full p-5 text-lg lg:text-xl xl:text-2xl text-shadow-md;
}

.webkit-fill {
  height: 100vh;
  height: 100cqh;
}

.commonGrid {
  @apply md:grid grid-cols-12 gap-10;
}

.commonGridOuter {
  @apply px-10 md:px-32;
}

.fadebg {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8211659663865546) 75%, rgba(255,255,255,1) 100%);
}

.gallery-next, .gallery-prev {
  @apply absolute top-0 w-1/4 h-full z-[100];
}

.gallery-next {
  @apply right-0;
  cursor: url('../images/cursor-right.svg'), auto;
}

.gallery-prev {
  @apply left-0;
  cursor: url('../images/cursor-left.svg'), auto;
}

.mainContent {
  @apply max-w-[64rem];
}

#menuOpener {
  @apply transition-transform transform origin-center duration-300;
}



#menuOpener.active {
  @apply rotate-45;
}

.vidCover video {
  @apply w-full h-full max-w-none object-cover;
}

.centerTextHeader {
  @apply p-5 pt-20  pb-10 text-center w-full;
}

.centerTextHeader h1 {
  @apply text-marcusBlue m-auto text-xl
}

.webkit-fill {
  height: 100vh;
  height: 100cqh;
}

/* purgecss start ignore */

media-player {
  --newColour: rgb(0, 0, 255);
  
  
  --media-slider-track-fill-bg: var(--newColour);
  --media-slider-track-fill-live-bg: var(--newColour);
  --media-controls-color: var(--newColour);
  --media-button-hover-bg: none;
}
media-time-slider {
  --track-height: 2px;
  --media-slider-track-bg: var(--newColour);
  --media-slider-track-progress-bg: var(--newColour);
  --track-focus-height: 2px;
  --media-slider-thumb-bg: var(--newColour);
  --media-slider-thumb-border: none;
}

.posterCover media-poster img {
  @apply object-cover h-full;
}

:where(media-player [data-media-slider] [part='thumb']) {
  @apply opacity-100;
}

:root {
    --plyr-color-main: #F6EFEF;
}

.plyr--hide-controls .video-info {
    opacity: 0;
}

.plyr--hide-controls .video-darken {
    opacity: 0;
}


:where(media-player[data-view-type='video']:not([data-fullscreen])) :where(media-outlet, video, media-poster, div[part='scrim']) {

  @apply rounded-none;

}

:where(media-player[data-view-type='video']) {
  @apply bg-transparent;

}

body > div.flex-1 > div > div.relative.min-h-\[calc\(100vh-96px\)\].pt-10.md\:pt-20.pb-24 > div.mx-auto.px-10.mainContent.flex.flex-wrap.justify-center.gap-8 > div.py-10.w-full > media-player > media-outlet > shadow-root > video




.plyr__video-wrapper {
    width: 100vw ;
    max-height: 100% ;
    max-width: 177.5vh ;
}

.customPlayer {
    z-index: 100;
}

.swiper-pagination-bullet {
    @apply bg-white opacity-90 !important;
}

.swiper-pagination-bullet-active {
    @apply bg-indigo-400 opacity-100 !important;
}

.swiper-button-disabled {
  @apply pointer-events-none;
}

.twic {
    opacity: 0;
    will-change: opacity;
    transition: opacity 1s linear, transform .75s ease-out;
  }

  .twic-poster-done {
    opacity: 1;
  }

  .twic-done {
    opacity: 1;
  }

  .mf-cursor::before {
    @apply bg-white;
  }

  .mf-cursor.-pointer:before {
    transform: scale(0.5);
  }

  .mf-cursor.-menu:before {
    transform: scale(0.82);
    @apply bg-indigo-600 opacity-40;
  }

  .mf-cursor.-arrow .mf-cursor-media-box {
    transform: scale(0.1);
  }

  .mf-cursor.-play .mf-cursor-media-box {
    transform: scale(0.1);
  }

  .mf-cursor.-arrow:before {
    @apply opacity-0;
  }

  .mf-cursor.-directorList .mf-cursor-media {
    width: 340px;
    height: 340px;
    transform: translate(-80px, -80px);
  }

/* purgecss end ignore */