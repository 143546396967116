@import "tailwindcss/base";
@import "tailwindcss/components";

@import "./typography.css";
@import "./video.css";
@import "./defaults.css";

@import "./plyr2.css";
@import "./layout.css";



@import "tailwindcss/utilities";