

@font-face {
  font-family: 'Word';
  font-weight: 400;
  font-style: normal;
  src:
    url(../fonts/Word-Book.woff) format("woff"),
    url(../fonts/Word-Book.woff2) format("woff2");
}


